.word-pyramid-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-flex: 2;
      -ms-flex: 2;
          flex: 2;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.word-pyramid-container .word-pyramid-row-container {
  background-color: white;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
}

.word-pyramid-container .letter-square-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  border: 1px black solid;
  margin: 1px;
  background-color: white;
  width: 10vmin;
  height: 10vmin;
  font-family: arial, sans-serif;
  font-size: 2em;
  color: black;
  border-radius: 3px;
}

.word-pyramid-container .letter-square-container.selected {
  background-color: #ffd83c;
}

.word-pyramid-container .letter-square-container.highlighted {
  background-color: #a7d9ff;
}

.word-pyramid-container .letter-square-container .corner-number {
  position: absolute;
  font-size: 0.5em;
  margin-top: -5.71429vmin;
  margin-left: -7.14286vmin;
}

.word-pyramid-container .letter-square-container .current-letter {
  color: black;
}

.word-pyramid-container .letter-square-container .correct-letter {
  color: #58b858;
}

.word-pyramid-container .letter-square-container .incorrect-letter {
  color: #c90000;
}

@media only screen and (max-width: 600px) {
  .word-pyramid-container {
    max-height: 55%;
  }
}
