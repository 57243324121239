$keyboard-background-color: rgb(224, 231, 237);
$clue-bar-background-color: #a7d9ff;
$keyboard-button-background-color: white;
$keyboard-button-text-color: black;

.keyboard-container {
  // background-color: pink;
  background-color: $keyboard-background-color;
  width: 100vw;

  display: flex;
  // flex: 1;
  justify-content: bottom;
  flex-direction: column;

  position: fixed;
  bottom: 0;
  left: 50%;
  transform: translate(-50%);

  .clue-bar-container {
    background-color: $clue-bar-background-color;
    // bottom: 170px;
    // font: 19px/21px nyt-franklin,Arial;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    z-index: 1;

    .clue-bar-arrow {
      padding: 0px 16px;
      cursor: pointer;
    }
  }

  .keyboard {
    // hide for desktop
    display: none;

    z-index: 0;
    position: relative;

    .keyboard-row {
      display: flex;
      flex-direction: row;
      align-content: center;
      justify-content: center;
      height: 8vh;
      margin: 12px 0px;

      .keyboard-button {
        background-color: $keyboard-button-background-color;
        color: $keyboard-button-text-color;
        min-width: 10vw;
        min-height: 8vh;
        // margin: 0.35vw;

        border: 3px solid $keyboard-background-color;

        border-radius: 8px;
        z-index: 0;
        position: relative;
        box-shadow: none;

        box-sizing: border-box;
        cursor: pointer;
        display: flex;
        justify-content: center;

        align-items: center;
      }

      // centers the bottom row as if the delete button was the same size as other buttons
      .z-button {
        margin-left: 5vh;
      }

      .delete-button {
        min-width: 19vw;
      }
    }
  }
}

@media only screen and (max-width: 600px) {
  .clue-bar-container {
    min-height: 60px;
  }

  .keyboard-container {
    .keyboard {
      display: unset;
    }
  }
}