.text-center {
  text-align: center;
}

.pyramini-calendar {
  border: none;

  .react-calendar__navigation {
    margin: 0px;
  }

  .react-calendar__month-view__weekdays__weekday {
    padding: 0px;
    font-size: 0.8em;

    abbr {
      text-decoration: none;
    }
  }

  // Make the text black instead of default red.
  .react-calendar__month-view__days__day,
  .react-calendar__navigation__label,
  .react-calendar__navigation__arrow {
    color: black;
  }

  // Return disabled text to gray after previous code made it black.
  button:disabled {

    &.react-calendar__month-view__days__day,
    &.react-calendar__navigation__label,
    &.react-calendar__navigation__arrow {
      color: #757575;
    }
  }

  .react-calendar__tile {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;

    abbr {
      margin-top: 5px;
    }

    &.react-calendar__month-view__days__day {
      padding: 6px 2px;
    }

    &.react-calendar__tile--active {
      color: white;
    }
  }
}

@media (max-width: 480px) {
  .calendar-container {
    width: 80%;
  }
}