.keyboard-container {
  background-color: #e0e7ed;
  width: 100vw;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: bottom;
      -ms-flex-pack: bottom;
          justify-content: bottom;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  position: fixed;
  bottom: 0;
  left: 50%;
  -webkit-transform: translate(-50%);
          transform: translate(-50%);
}

.keyboard-container .clue-bar-container {
  background-color: #a7d9ff;
  height: 40px;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  width: 100%;
  z-index: 1;
}

.keyboard-container .clue-bar-container .clue-bar-arrow {
  padding: 0px 16px;
  cursor: pointer;
}

.keyboard-container .keyboard {
  display: none;
  z-index: 0;
  position: relative;
}

.keyboard-container .keyboard .keyboard-row {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -ms-flex-line-pack: center;
      align-content: center;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 8vh;
  margin: 12px 0px;
}

.keyboard-container .keyboard .keyboard-row .keyboard-button {
  background-color: white;
  color: black;
  min-width: 10vw;
  min-height: 8vh;
  border: 3px solid #e0e7ed;
  border-radius: 8px;
  z-index: 0;
  position: relative;
  -webkit-box-shadow: none;
          box-shadow: none;
  -webkit-box-sizing: border-box;
          box-sizing: border-box;
  cursor: pointer;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.keyboard-container .keyboard .keyboard-row .z-button {
  margin-left: 5vh;
}

.keyboard-container .keyboard .keyboard-row .delete-button {
  min-width: 19vw;
}

@media only screen and (max-width: 600px) {
  .clue-bar-container {
    min-height: 60px;
  }
  .keyboard-container .keyboard {
    display: unset;
  }
}
