.title-bar-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  background-color: #81b9e3;
  max-height: 40px;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.title-bar-container .title-bar-left-side {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: left;
      -ms-flex-pack: left;
          justify-content: left;
  height: 100%;
}

.title-bar-container .title-bar-middle {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  height: 100%;
}

.title-bar-container .title-bar-middle .title {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  color: white;
  text-decoration: none;
}

.title-bar-container .title-bar-right-side {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-pack: right;
      -ms-flex-pack: right;
          justify-content: right;
  height: 100%;
}

.title-bar-container .title-bar-button {
  margin: 0px 7px;
  cursor: pointer;
  height: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.title-bar-container ul {
  list-style-type: none;
  padding: unset;
  margin-block: unset;
}

.title-bar-container .help-dropdown-container {
  height: 100%;
}

.title-bar-container .help-dropdown {
  position: absolute;
  z-index: 2;
  text-align: left;
  color: black;
}

.title-bar-container .help-dropdown .selected {
  background-color: #a7d9ff;
}

.title-bar-container .help-dropdown li {
  padding: 5px;
  margin: 3px;
  border: 2px black solid;
  border-radius: 5px;
}

.title-bar-container .help-dropdown li .left-arrow {
  margin-right: 5px;
}

.title-bar-container .help-dropdown li:hover {
  background-color: #ffd83c;
}

.title-bar-container .help-dropdown .check-dropdown,
.title-bar-container .help-dropdown .reveal-dropdown,
.title-bar-container .help-dropdown .clear-dropdown {
  position: absolute;
  right: 106px;
  text-align: center;
}

@media only screen and (max-width: 768px) {
  .title-bar-container .help-dropdown {
    /* For mobile phones: */
  }
  .title-bar-container .help-dropdown .check-dropdown,
  .title-bar-container .help-dropdown .reveal-dropdown,
  .title-bar-container .help-dropdown .clear-dropdown {
    position: fixed;
    left: 0;
    right: unset;
    text-align: center;
  }
}
