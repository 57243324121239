.text-center {
  text-align: center;
}

.pyramini-calendar {
  border: none;
}

.pyramini-calendar .react-calendar__navigation {
  margin: 0px;
}

.pyramini-calendar .react-calendar__month-view__weekdays__weekday {
  padding: 0px;
  font-size: 0.8em;
}

.pyramini-calendar .react-calendar__month-view__weekdays__weekday abbr {
  text-decoration: none;
}

.pyramini-calendar .react-calendar__month-view__days__day,
.pyramini-calendar .react-calendar__navigation__label,
.pyramini-calendar .react-calendar__navigation__arrow {
  color: black;
}

.pyramini-calendar button:disabled.react-calendar__month-view__days__day, .pyramini-calendar button:disabled.react-calendar__navigation__label, .pyramini-calendar button:disabled.react-calendar__navigation__arrow {
  color: #757575;
}

.pyramini-calendar .react-calendar__tile {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: reverse;
      -ms-flex-direction: column-reverse;
          flex-direction: column-reverse;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.pyramini-calendar .react-calendar__tile abbr {
  margin-top: 5px;
}

.pyramini-calendar .react-calendar__tile.react-calendar__month-view__days__day {
  padding: 6px 2px;
}

.pyramini-calendar .react-calendar__tile.react-calendar__tile--active {
  color: white;
}

@media (max-width: 480px) {
  .calendar-container {
    width: 80%;
  }
}
