.modal-overlay {
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.modal-container {
  display: block;
  background: white;
  width: 80%;
  height: 80%;
  padding: 1rem;
  border-radius: 1rem;
}

.modal-container .modal-header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: end;
      -ms-flex-pack: end;
          justify-content: flex-end;
}

.modal-container .modal-header .modal-close-button {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
      -ms-flex-pack: center;
          justify-content: center;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  border-radius: 100%;
  width: 35px;
  height: 35px;
  color: black;
  cursor: pointer;
}

.modal-container .modal-header .modal-close-button:hover {
  background-color: #a7d9ff;
}

.modal-container .modal-content {
  color: black;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}

.modal-container .modal-content .button {
  font-size: inherit;
  color: inherit;
  border: none;
  padding: 10px;
  border-radius: 10px;
  font: inherit;
  cursor: pointer;
  outline: none;
  margin: 10px;
  vertical-align: middle;
  background-color: #25b12c;
  color: white;
}

.modal-container .modal-content .copy-button {
  cursor: copy;
}

.settings-modal-content {
  min-width: 80%;
}

.setting-container {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: justify;
      -ms-flex-pack: justify;
          justify-content: space-between;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
      -ms-flex-direction: row;
          flex-direction: row;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
  margin-top: 10px;
}

.setting-container .left-side {
  float: left;
  text-align: left;
  -webkit-box-flex: 1;
      -ms-flex: 1;
          flex: 1;
}

.setting-container .left-side .secondary-text {
  font-size: 0.7em;
}

.setting-container .right-side {
  display: inline-block;
}

.modal-section-separator {
  margin-top: 10px;
  border-bottom: 2px solid black;
}

.link-text, .link-text:link, .link-text:visited {
  color: white;
  text-decoration: none;
}

.link-text:hover {
  color: white;
}

.icon-button {
  cursor: pointer;
  margin-left: 10px;
}

.past-games-modal-content {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
      -ms-flex-direction: column;
          flex-direction: column;
  -webkit-box-align: center;
      -ms-flex-align: center;
          align-items: center;
}
