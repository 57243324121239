$square-size: 10vmin;
$selected-color: rgb(255, 216, 60);
$highlighted-color: #a7d9ff;
// $letter-square-color: #e99e50;
$letter-square-color: white;
$letter-square-border-color: black;
$border-color: black;
$text-color: black;
// $corner-number-color: black;
$background-color: white;
$correct-color: rgb(88, 184, 88);
$incorrect-color: rgb(201, 0, 0);

.word-pyramid-container {
  display: flex;
  flex-direction: column;
  flex: 2;
  justify-content: center;

  .word-pyramid-row-container {
    background-color: $background-color;
  
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  
  .letter-square-container {
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px $border-color solid;
    margin: 1px;
    background-color: $letter-square-color;
    width: $square-size;
    height: $square-size;
    font-family: arial, sans-serif;
    font-size: 2em;
    color: $text-color;
    border-radius: 3px;
  
    &.selected {
      background-color: $selected-color;
    }
    
    &.highlighted {
      background-color: $highlighted-color;
    }
  
    .corner-number {
      // color: $corner-number-color;
      position: absolute;
      font-size: 0.5em;
      margin-top: -1 * (4 / 7) * $square-size;
      margin-left: -1 * (5 / 7) * $square-size;
    }

    .current-letter {
      color: black;
    }

    .correct-letter {
      color: $correct-color;
    }

    .incorrect-letter {
      color: $incorrect-color;
    }
  }  
}

@media only screen and (max-width: 600px) {
  .word-pyramid-container {
    max-height: 55%;
  }
}