.title-bar-container {
  display: flex;
  flex-direction: row;
  background-color: #81b9e3;
  max-height: 40px;
  align-items: center;

  flex: 1;

  .title-bar-left-side {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: left;
    height: 100%;
  }

  .title-bar-middle {
    display: flex;
    flex: 1;
    justify-content: center;
    height: 100%;

    .title {
      display: flex;
      justify-content: center;
      align-items: center;

      color: white;
      text-decoration: none;
    }
  }

  .title-bar-right-side {
    display: flex;
    flex: 1;
    flex-direction: row;
    justify-content: right;
    height: 100%;
  }

  .title-bar-button {
    margin: 0px 7px;
    cursor: pointer;
    height: 100%;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  ul {
    list-style-type: none;
    padding: unset;
    margin-block: unset;
  }

  .help-dropdown-container {
    height: 100%;
  }

  .help-dropdown {
    position: absolute;
    z-index: 2;
    text-align: left;
    color: black;

    .selected {
      background-color: #a7d9ff;
    }

    li {
      padding: 5px;
      margin: 3px;
      border: 2px black solid;
      border-radius: 5px;

      .left-arrow {
        margin-right: 5px;
      }
    }

    li:hover {
      background-color: rgb(255, 216, 60);
    }

    .check-dropdown,
    .reveal-dropdown,
    .clear-dropdown {
      position: absolute;
      right: 106px;
      text-align: center;
    }

    @media only screen and (max-width: 768px) {

      /* For mobile phones: */
      .check-dropdown,
      .reveal-dropdown,
      .clear-dropdown {
        position: fixed;
        left: 0;
        right: unset;
        text-align: center;
      }
    }
  }
}