.modal-overlay {
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  position: absolute;
  top: 0;
  background: rgba(0, 0, 0, 0.7);
  display: flex;
  justify-content: center;
  align-items: center;
}

.modal-container {
  display: block;
  background: white;
  width: 80%;
  height: 80%;
  padding: 1rem;
  border-radius: 1rem;

  .modal-header {
    display: flex;
    justify-content: flex-end;

    .modal-close-button {
      display: flex;
      justify-content: center;
      flex-direction: column;

      border-radius: 100%;
      width: 35px;
      height: 35px;
      color: black;
      cursor: pointer;
    }

    .modal-close-button:hover {
      background-color: #a7d9ff;
    }
  }

  .modal-content {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: center;

    .button {
      font-size: inherit;
      color: inherit;
      border: none;
      padding: 10px;
      border-radius: 10px;
      font: inherit;
      cursor: pointer;
      outline: none;
      margin: 10px;
      // max-height: 2em;
      vertical-align: middle;

      background-color: #25b12c; 
      color: white;
    }

    .copy-button {
      cursor: copy;
    }
  }
}

.settings-modal-content {
  min-width: 80%;
}

.setting-container {
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  margin-top: 10px;

  .left-side {
    float: left;
    text-align: left;
    flex: 1;

    .secondary-text {
      font-size: 0.7em;
    }
  }

  .right-side {
    display: inline-block;
  }
}

.modal-section-separator {
  margin-top: 10px;
  border-bottom: 2px solid black;
}

.link-text, .link-text:link, .link-text:visited {
  color: white;
  text-decoration: none;
}

.link-text:hover {
  color: white;
}

.icon-button {
  cursor: pointer;
  margin-left: 10px;
}

.past-games-modal-content {
  display: flex;
  flex-direction: column;
  align-items: center;
}